<template>
  <div :class="`page-view article ${cfg.category}`">
    <SeoComponent
      :seoTitle="
        `${
          thePost.title ? thePost.title + ' | ' + this.cfg.nameCategory : 'IME'
        }`
      "
      :seoDescription="
        `${
          thePost.summary
            ? thePost.summary
            : 'Instituto de los Mexicanos en el Exterior'
        }`
      "
      :seoTags="`${thePost.keywords ? thePost.keywords : 'gobmx,sre,ime'}`"
      :seoCanonical="
        `${
          thePost.canonical
            ? this.cfg.category + '/articulo/' + thePost.canonical
            : '/'
        }`
      "
      :seoImage="
        `${
          thePost.image_home_local
            ? path.storage_files + thePost.image_home_local.url
            : '/images/categorias/vaipoime.jpg'
        }`
      "
    />

    <section v-if="currentPost">
      <div v-for="post in currentPost" :key="post.id">
        <div
          class="header-page z-depth-5"
          :style="
            `background-image: url(${
              post.cover_image_local
                ? path.storage_files + post.cover_image_local.url
                : post.cover_image
            })`
          "
        >
          <div class="gradiente-page">
            <b-container class="">
              <h1
                class="display-3 white-text text-center text-shadow super-strong"
              >
                {{ post.title }}
              </h1>
            </b-container>
          </div>
        </div>

        <b-container class="mt-5 pt-4">
          <b-row>
            <b-col col lg="8" sm="12">
              <div class="data-text">
                <b-button variant="danger" class="rojo light text-uppercase">
                  <i class="fas fa-tag"></i>&nbsp; {{ post.category_id.name }}
                </b-button>
                &nbsp;
                <b-button variant="secondary" class="light text-uppercase">
                  <i class="fas fa-calendar-alt"></i>&nbsp;
                  {{ post.published_at | moment("DD/MMM/YYYY h:mm a") }}
                </b-button>
                &nbsp;

                <b-button
                  variant="primary"
                  v-b-tooltip.hover
                  title="Comparte en Facebook"
                  :href="
                    `https://www.facebook.com/sharer.php?u=http://ime.gob.mx/${cfg.category}/${post.canonical}&t=${post.title}`
                  "
                  target="_blank"
                >
                  <i class="fab fa-facebook"></i>
                </b-button>
                &nbsp;
                <b-button
                  variant="info"
                  v-b-tooltip.hover
                  title="Comparte en Twitter"
                  :href="
                    `https://twitter.com/?status=http://ime.gob.mx/${cfg.category}/${post.canonical}`
                  "
                  target="_blank"
                >
                  <i class="fab fa-twitter"></i>
                </b-button>
                &nbsp;
                <b-button
                  v-b-tooltip.hover
                  title="Comparte en Whatsapp"
                  class="green accent-4 border-wa"
                  :href="
                    `https://api.whatsapp.com/send?text=http://ime.gob.mx/${cfg.category}/${post.canonical}`
                  "
                  target="_blank"
                >
                  <i class="fab fa-whatsapp"></i>
                </b-button>
              </div>
              <hr class="line" />

              <blockquote class="light p-4 shadow-sm grey lighten-4">
                {{ post.summary }}
              </blockquote>

              <section
                v-if="post.video_position === 'body'"
                class="video-post mb-5 green darken-1 white-text p-4 box-20 z-depth-3"
              >
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  :src="
                    ` ${
                      post.video_id
                        ? 'https://www.youtube-nocookie.com/embed/' +
                          post.video_id +
                          '?rel=0'
                        : 'https://embed.streamyard.com/' +
                          post.video_streamyard
                    } `
                  "
                  allowfullscreen
                  class="video box-10 z-depth-2"
                ></b-embed>
                <p class="text-center mt-2">{{ post.video_description }}</p>
              </section>
              <section v-else class="markdown-content mb-5">
                <vue-simple-markdown
                  :source="`${post.paragraph}`"
                ></vue-simple-markdown>
              </section>

              <section v-if="post.timeline" class="mt-5 mb-5">
                <TimelineCentral
                  :cfgPostId="post.timeline.id"
                ></TimelineCentral>
              </section>

              <section v-if="post.multimedia" class="video-gallery">
                <CarouselVideo
                  v-if="post.multimedia.position_videos === 'body'"
                  :cfgTitle="post.multimedia.title_videos"
                  :cfgArrayGallery="post.multimedia.videos"
                  :cfgCategory="cfg.category"
                />
              </section>

              <div v-if="post.multimedia" class="pt-5 ">
                <MasonryGallery
                  v-if="post.multimedia.position_images === 'body'"
                  :cfgTitle="post.multimedia.title_images"
                  :cfgArrayGallery="post.multimedia.images"
                  :cfgCategory="cfg.category"
                />
              </div>

              <section
                v-if="
                  post.add_accordion === true &&
                    post.module_type === 'accordion'
                "
              >
                <Accordion
                  :cfgTitle="post.AccordionName"
                  :cfgExternalContent="post.external_content"
                  :cfgArrayAccordion="post.Accordion"
                ></Accordion>
              </section>

              <section
                v-if="
                  post.add_accordion === true && post.module_type === 'cards'
                "
              >
                <CardModule
                  :cfgTitle="post.AccordionName"
                  :cfgExternalContent="post.external_content"
                  :cfgArrayAccordion="post.Accordion"
                ></CardModule>
              </section>
            </b-col>

            <b-col col lg="4" sm="12" class="d-sm-none d-md-block">
              <Sidebar
                :videoView="`${post.video_view}`"
                :videoPosition="`${post.video_position}`"
                :videoTitle="`${post.video_title}`"
                :videoDescription="`${post.video_description}`"
                :videoId="`${post.video_id}`"
                :socialMedia="post.category_id.social_media"
                twitterLimit="1"
                :imageCover="
                  `${
                    post.image_home_local
                      ? path.storage_files + post.image_home_local.url
                      : '/images/aguila.png'
                  }`
                "
                :imageTitle="`${post.title}`"
              ></Sidebar>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>

    <section v-else>
      <br />
      <h1 class="display-1">Error 404</h1>
    </section>
  </div>
</template>

<script>
import SeoComponent from "@/components/Global/Seo";
import Sidebar from "@/components/layout/Sidebar";
import MasonryGallery from "@/components/Custom/Programs/MasonryGallery";
import CarouselVideo from "@/components/Custom/Programs/CarouselVideo";
import Accordion from "@/components/Custom/Accordions/AccordionBody";
import CardModule from "@/components/Custom/Cards/CardBody";
import TimelineCentral from "@/components/Custom/Timeline/Central";

export default {
  name: "ShowPost",
  components: {
    SeoComponent,
    MasonryGallery,
    CarouselVideo,
    Sidebar,
    Accordion,
    CardModule,
    TimelineCentral,
  },

  data() {
    return {
      currentPost: [],
      thePost: {},

      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg: {
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    };
  },

  /*
   * Muestra resultado de campo canonical/slug
   */
  async created() {
    await fetch(
      this.path.endpoint + "/blogs/?canonical=" + this.$route.params.canonical // <- Ajuste segun Post
    )
      .then((response) => response.json())
      .then((data) => {
        this.currentPost = data;
        this.thePost = data[0];
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Post */
};
</script>
